<template>
  <div class="goods-list" v-loading="loading" @click="showSetting">
    <!-- 搜索关键字 -->
    <!--<div class="search_bread" v-if="keyword">
      <span>全部结果<i class="iconfont iconarrow-right"></i></span>
      <span class="keyword">{{ keyword}}</span>
    </div>
    <div class="search_bread" v-else-if="!keyword && catewords">
      <span>全部结果<i class="iconfont iconarrow-right"></i></span>
      <span class="keyword">{{ catewords}}</span>
    </div>-->

    <!-- 分类-->
    <div class="c-filter">
      <div class="c-check2 c-check4 row margin0">
        <div class="col-xs-11 padd0">
          <span class="float-left category_label">全部商品：</span>
          <div class="category_line" id="category_id" @click="settingEvent($event)">
            <ul>
              <li class="category_tab" id="category_btn_a" @click="selectedCategory(0, 1, false)">
                <span class="category_show" id="category_a">{{ goodsCategory_name1 }}</span>
                <i class="iconfont iconweibiaoti35 purchase_cArrow"></i>
              </li>
              <li class="category_arrow">
                <i class="iconfont iconarrow-right s-arrowT"></i>
              </li>
              <li class="category_tab" id="category_btn_b" @click="selectedCategory(checkCategory2, 2, false)">
                <span class="category_show" id="category_b">{{ goodsCategory_name2 }}</span>
                <i class="iconfont iconweibiaoti35 purchase_cArrow"></i>
              </li>
              <li class="category_arrow">
                <i class="iconfont iconarrow-right s-arrowT"></i>
              </li>
              <li class="category_tab" id="category_btn_c" @click="selectedCategory(checkCategory3, 3, false)">
                <span class="category_show" id="category_c">{{ goodsCategory_name3 }}</span>
                <i class="iconfont iconweibiaoti35 purchase_cArrow"></i>
              </li>
            </ul>
            <div class="category_box" id="category_box_a" v-show="goodsCategorylevel == 1">
              <span @click="selectedCategory(0, 1, true)">全部</span>
              <span v-for="(item, index) in goodsCategoryA" :key="index" :data-pid="item.category_id"
                :class="{ active: goodsCategory1 === item.category_id }" :data-level="item.level"
                @click="selectedChildCategory(item, 1)">{{ item.category_name }}</span>
            </div>
            <div class="category_box" id="category_box_b" v-show="goodsCategorylevel == 2">
              <span @click="selectedCategory(checkCategory2, 2, true)">全部</span>
              <span v-for="(item, index) in goodsCategoryB" :key="index" :data-pid="item.category_id"
                :class="{ active: goodsCategory2 === item.category_id }" :data-level="item.level"
                @click="selectedChildCategory(item, 2)">{{ item.category_name }}</span>
            </div>
            <div class="category_box" id="category_box_c" v-show="goodsCategorylevel == 3">
              <span @click="selectedCategory(checkCategory3, 3, true)">全部</span>
              <span v-for="(item, index) in goodsCategoryC" :key="index" :data-pid="item.category_id"
                :class="{ active: goodsCategory3 === item.category_id }" :data-level="item.level"
                @click="selectedChildCategory(item, 3)">{{ item.category_name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 品牌过滤记录区 -->
    <div class="attr_filter" v-if="catewords">
      <el-tag type="info" v-if="choosedBrand" closable @close="colseBrand" effect="plain">
        <span v-if="choosedBrand" class="title">品牌：</span>
        {{ choosedBrand.brand_name }}
      </el-tag>
      <span v-for="item in attributeList" :key="item.attr_id">
        <el-tag type="info" v-if="item.selectedValue" closable @close="colseAttr(item)" effect="plain">
          <span class="title" v-if="item.attr_name">{{ item.attr_name }}：</span>
          {{ item.selectedValue }}
        </el-tag>
      </span>
    </div>

    <!-- 品牌属性筛选区 -->
    <template>
      <div class="category" v-if="brandList.length || attributeList.length">
        <!-- 品牌 -->
        <div class="brand">
          <div class="table_head">品牌：</div>
          <div class="table_body">
            <div class="initial">
              <span type="info" effect="plain" hit @mouseover="handleChangeInitial('')">所有品牌</span>
              <span type="info" effect="plain" hit v-for="item in brandInitialList" :key="item"
                @mouseover="handleChangeInitial(item)">{{ (item || '').toUpperCase() }}</span>
            </div>
            <div class="content">
              <el-card v-for="item in brandList" :key="item.id" body-style="padding: 0;height: 100%;" shadow="hover"
                v-show="currentInitial === '' || item.brand_initial === currentInitial" class="brand-item">
                <el-image :src="$img(item.image_url || defaultGoodsImage)" :alt="item.brand_name"
                  :title="item.brand_name" fit="contain" @click="onChooseBrand(item)" />
              </el-card>
            </div>
          </div>
        </div>

        <!-- 属性 -->
        <div class="brand" v-for="item in attributeList" :key="'attr' + item.attr_id">
          <div class="table_head">{{ item.attr_name }}：</div>
          <div class="table_body">
            <div class="content">
              <span v-for="subitem in item.child" :key="subitem.attr_value_id">
                <el-checkbox :label="subitem.attr_value_name" v-if="item.isMuiltSelect" :checked="subitem.selected"
                  @change="setAttrSelectedMuilt(item, subitem)"></el-checkbox>
                <el-link :underline="false" v-else @click="setAttrSelected(item, subitem)">{{ subitem.attr_value_name }}
                </el-link>
              </span>
            </div>
          </div>
          <div class="table_op">
            <el-button size="small" icon="el-icon-circle-plus-outline" @click="setMuiltChoose(item)">多选</el-button>
          </div>
        </div>
      </div>
    </template>

    <div class="list-wrap">
      <div class="goods-recommended" v-if="cargoList.length">
        <goods-recommend :page-size="cargoList.length < 5 ? 2 : 5" />
      </div>
      <div class="list-right">
        <!-- 排序筛选区 -->
        <div class="sort-box">
          <div class="sort">
            <div class="f-sort">
              <div class="item" @click="changeSort('')">
                <div class="item-name">综合</div>
              </div>
              <div class="item" @click="changeSort('sale_num')">
                <div class="item-name">销量</div>
                <i v-if="filters.order === 'sale_num' && filters.sort === 'desc'"
                  class="el-icon-arrow-down el-icon--down" />
                <i v-else class="el-icon-arrow-up el-icon--up" />
              </div>
              <div class="item" @click="changeSort('discount_price')">
                <div class="item-name">价格</div>
                <i v-if="filters.order === 'discount_price' && filters.sort === 'desc'"
                  class="el-icon-arrow-down el-icon--down" />
                <i v-else class="el-icon-arrow-up el-icon--up" />
              </div>
              <div class="item item-other" style="border-left: 1px solid #CCC;">
                <el-checkbox label="包邮" v-model="is_free_shipping"></el-checkbox>
              </div>
              <div class="item item-other">
                <el-checkbox label="自营" v-model="is_own"></el-checkbox>
              </div>
            </div>

            <div class="input-wrap">
              <el-input class="el-input-text" placeholder="¥" size="small" v-model="filters.min_price"></el-input>
              <span>—</span>
              <el-input class="el-input-text" placeholder="¥" size="small" v-model="filters.max_price"></el-input>
              <el-button plain size="mini" @click="handlePriceRange">确定</el-button>
            </div>

            <div class="sort-city" @click="settingEvent($event)">
              <div class="region-label">产地</div>
              <div class="region-selected ns-border-color-gray" @click="changeSortCity">
                <span>
                  <template v-if="selectedAddress['level_1']">
                    <template v-for="item in selectedAddress">
                      {{ item.name }}
                    </template>
                  </template>
                  <template v-else>
                    请选择产地
                  </template>
                </span>
                <i class="el-icon-arrow-down"></i>
              </div>

              <div class="region-list ns-border-color-gray" v-show="hideCity">
                <ul class="nav-tabs">
                  <li :class="{ active: currTabAddres == 'province' }" @click="currTabAddres = 'province'">
                    <div>
                      <span>{{ selectedAddress['level_1'] ? selectedAddress['level_1'].name : '请选择省' }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </li>
                  <li :class="{ active: currTabAddres == 'city' }" @click="currTabAddres = 'city'">
                    <div>
                      <span>{{ selectedAddress['level_2'] ? selectedAddress['level_2'].name : '请选择市' }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </li>
                  <li :class="{ active: currTabAddres == 'district' }" @click="currTabAddres = 'district'">
                    <div>
                      <span>{{ selectedAddress['level_3'] ? selectedAddress['level_3'].name : '请选择区/县' }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane" :class="{ active: currTabAddres == 'province' }">
                    <ul class="province">
                      <li :class="{ selected: selectedAddressAll.provinceId === 0 }" @click="getAddressAll('province')">
                        <span>全国</span></li>
                      <li v-for="(item, index) in provinceArr" :key="index"
                        :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
                        <span @click="getAddress('city', item)">{{ item.name }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-pane" :class="{ active: currTabAddres == 'city' }">
                    <ul class="city">
                      <li :class="{ selected: selectedAddressAll.cityId === 0 }" @click="getAddressAll('city')">
                        <span>不限</span></li>
                      <li v-for="(item, index) in cityArr" :key="index"
                        :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
                        <span @click="getAddress('district', item)">{{ item.name }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-pane" :class="{ active: currTabAddres == 'district' }">
                    <ul class="district">
                      <li :class="{ selected: selectedAddressAll.district === 0 }" @click="getAddressAll('district')">
                        <span>不限</span></li>
                      <li v-for="(item, index) in districtArr" :key="index"
                        :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
                        <span @click="getAddress('community', item)">{{ item.name }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cargo-list" v-if="cargoList.length">
          <div class="goods-info">
            <div class="item" v-for="(item, index) in cargoList" :key="item.goods_id"
              @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
              <span class="tag taggreen" v-if="item.recommend_way == 1">新品</span>
              <span class="tag tagblue" v-if="item.recommend_way == 2">精品</span>
              <span class="tag" v-if="item.recommend_way == 3">推荐</span>
              <img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />
              <div class="price-wrap">
                <div class="price">
                  <p>￥</p>
                  {{ item.discount_price }}
                </div>
                <div class="market-price">￥{{ item.market_price }}</div>
              </div>
              <div class="goods-name" v-if="item.goods_name">

                <span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
                <span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
                <span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
                <span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>
                {{ item.goods_name }}
              </div>
              <div class="goods-name" v-else="item.sku_name">

                <span class="p_tag_832" v-if="item.tag_type == 1">832产品</span>
                <span class="p_tag_832" v-if="item.tag_level == 2">省贫认定</span>
                <span class="p_tag_832" v-if="item.tag_level == 3">市贫认定</span>
                <span class="p_tag_832" v-if="item.tag_level == 4">县贫认定</span>
                {{ item.sku_name }}

              </div>
              <div class="sale-num">
                <p>{{ item.sale_num || 0 }}</p>人付款
              </div>
              <div class="shop_name">{{ item.site_name }}</div>
              <div class="saling">
                <div class="free-shipping" v-if="item.is_free_shipping == 1">包邮</div>
                <div class="is_own" v-if="item.is_own == 1">自营</div>
                <div class="promotion-type" v-if="item.promotion_type == 1">限时折扣</div>
                <img style="height: 18px" v-if="item.has_camera" src="@/assets/images/live_yes.png" alt="" :key="0">
                <img style="height: 18px" v-else src="@/assets/images/live_no.png" alt="" :key="1">
              </div>
              <!-- <div class="item-bottom">
                  <div v-if="!item.isCollection" class="collection" @click.stop="editCollection(item)">
                    <i class="iconfont iconlike"></i>
                    收藏
                  </div>
				  <div v-if="item.isCollection" class="collection" @click.stop="editCollection(item)">
                    <i class="iconfont iconlikefill ns-text-color"></i>
                    取消收藏
                  </div>
                  <div class="cart" @click.stop="addToCart(item)">
                    <i class="el-icon-shopping-cart-2"></i>
                    加入购物车
                  </div>
              </div>-->
            </div>
          </div>
          <div class="pager">
            <el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
              :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
              @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
          </div>
        </div>
        <div class="empty" v-else>
          <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import GoodsRecommend from "@/components/GoodsRecommend";
import list from "./list";
import { getChildCategory } from "@/api/goods/goodscategory"
import { getArea } from "@/api/address";
import { mapGetters } from "vuex";

export default {
  name: "list",
  components: {
    BreadCrumbs,
    GoodsRecommend
  },
  data: () => {
    return {
      // 省市区县
      provinceArr: {},
      cityArr: {},
      districtArr: {},

      // 省市区县 id
      currTabAddres: "province",
      camera_name: "",
      hideRegion: false,
      selectedAddress: {},
      selectedAddressAll: {
        provinceId: 0,
        cityId: 0,
        district: 0,
      }
    };
  },
  mixins: [list],
  computed: {
    ...mapGetters(["token", "defaultHeadImage", "defaultShopImage", "defaultGoodsImage", "addonIsExit", 'locationRegion'])
  },
  created() { },
  mounted() {
    this.getAddress('province', null, true);
  },
  methods: {
    getAddressAll(type) {
      if (type === 'province') {
        this.selectedAddressAll.provinceId = 0
        this.selectedAddressAll.cityId = 0
        this.selectedAddressAll.district = 0
        this.selectedAddress = {}

        this.getAddress('province', null, true);
        this.filters.area_id = 0
        this.filters.area_level = 0
      } else if (type === 'city') {
        this.selectedAddressAll.cityId = 0
        this.selectedAddressAll.district = 0
        this.$delete(this.selectedAddress, 'level_2');
        this.$delete(this.selectedAddress, 'level_3');

        this.getAddress('city', this.selectedAddress.level_1)

        this.filters.area_id = this.selectedAddress.level_1.id
        this.filters.area_level = this.selectedAddress.level_1.level
      } else if (type === 'district') {
        this.selectedAddressAll.district = 0
        this.$delete(this.selectedAddress, 'level_3');
        this.getAddress('city', this.selectedAddress.level_2)

        this.filters.area_id = this.selectedAddress.level_2.id
        this.filters.area_level = this.selectedAddress.level_2.level
      }
      this.keyword = ''
      this.getGoodsList()
      // console.log('buxian====',this.selectedAddress)
    },
    /**
     * 获取地址
     * @param {Object} type
     * @param {Object} item
     * @param {Object} first 是否第一次
     */
    getAddress(type, item, first, callback) {
      let pid = 0
      switch (type) {
        case 'province':
          //加载省
          pid = 0
          break
        case 'city':
          //加载市
          if (item) {
            this.provinceId = item.id
          }
          this.selectedAddressAll.provinceId = item.id
          pid = this.provinceId
          this.cityArr = {}
          this.districtArr = {}
          break
        case 'district':
          //加载区县
          if (item) this.cityId = item.id
          this.selectedAddressAll.cityId = item.id
          pid = this.cityId
          this.districtArr = {}
          break
        case 'community':
          this.selectedAddressAll.district = item.id
          break
      }
      if (item) {
        if (item.level <= 2) {
          let len = item.level;
          for (let i = len; i <= 3; i++) {
            delete this.selectedAddress['level_' + i];
          }
        }
        this.selectedAddress['level_' + item.level] = item;
      }
      // console.log('getAddress====',this.selectedAddress)
      if (type !== 'province') {
        this.filters.area_id = item.id
        this.filters.area_level = item.level
        this.keyword = ''
        this.getGoodsList()
      }


      if (!first) this.$store.commit("app/SET_LOCATION_REGION", this.selectedAddress)
      this.$forceUpdate();
      if (type == 'community') {
        this.hideCity = false
        // setTimeout(() => {
        //   this.hideCity = true
        // }, 10);
        return;
      }

      getArea({
        pid: pid
      }).then(res => {
        const {
          code,
          data
        } = res;
        if (data) {
          switch (type) {
            case 'province':
              //加载省
              this.provinceArr = data
              break
            case 'city':
              //加载市
              this.cityArr = data
              break
            case 'district':
              //加载区县
              this.districtArr = data
              break
          }
          this.currTabAddres = type

          if (callback) callback();
        }
      })
        .catch(err => { })
    }
  }
};
</script>

<style lang="scss">
.el-input--small .el-input__inner {
  line-height: 28px !important;
  height: 28px !important;
  padding: 0px 10px;
}
</style>
<style lang="scss" scoped>
.goods-list {
  background: #fff;
  padding-top: 10px;
}

.search_bread {
  display: inline-block;
  font-size: 14px;
  margin: 0px auto;
  width: 100%;
  padding: 10px;

  i {
    font-size: 12px
  }
}

.selected_border {
  border: 1px solid $base-color;
}

.attr_filter {
  .el-tag {
    margin-right: 5px;
    margin-bottom: 10px;
    border-radius: 0;

    .title {
      color: $base-color;
      font-size: 15px;
    }
  }
}

.category {
  margin: 0 auto 10px auto;
  border: 1px solid #eeeeee;

  .brand {
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-direction: row;

    &:last-child {
      border-bottom: none;
    }

    .table_head {
      width: 140px;
      min-width: 140px;
      border-right: 1px solid #eeeeee;
      padding-left: 10px;
      padding-top: 10px;
      background-color: #f2f6fc;
    }

    .table_body {
      flex-grow: 1;

      .initial {
        margin: 5px auto 10px 10px;

        span {
          border: 0;
          margin: 0;
          padding: 5px 10px;
          border: 0;
          border-radius: 0;

          &:hover {
            background-color: $base-color;
            color: #ffffff;
          }
        }
      }

      .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        width: 900px;

        .brand-item {
          margin-right: 10px;
          margin-top: 10px;
        }

        .el-card {
          width: 125px;
          height: 45px;

          &:hover {
            border: 1px solid $base-color;
            cursor: pointer;
          }
        }

        span {
          margin: auto 25px;
        }
      }
    }

    .table_op {
      margin-top: 5px;
      margin-right: 5px;
    }

    .el-image {
      width: 100%;
      height: 100%;
    }
  }
}

.list-wrap {
  overflow: hidden;
  min-height: 500px;
}

.goods-recommended {
  width: 200px;
  background-color: #fff;
  float: left;
  margin-right: 10px;
}

.goods-info {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 232px;
    margin: 10px 20px 0 0;
    border: 1px solid #eeeeee;
    padding-top: 10px;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
    border-radius: 8px;

    .tag {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 17px;
      z-index: 1;
      font-size: 12px;
      width: 36px;
      background-color: #e84440;
      color: #fff;
      text-align: center;
      border-radius: 2px;
      letter-spacing: 2px;
    }

    .tag.taggreen {
      background-color: #07b85a;
    }

    .tag.tagblue {
      background-color: #0077FF;
    }


    &:nth-child(4n) {
      margin-right: initial !important;
    }

    &:hover {
      border: 1px solid $base-color;
    }

    .img-wrap {
      width: 198px;
      height: 198px;
      cursor: pointer;
      padding: 0px;
      margin: 0px auto;
      text-align: center;
      line-height: 170px;
      box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 30%);
      border-radius: 16px;
      overflow: hidden;
      position: relative;

      img {
        border: none;
        position: absolute;
        margin-right: -75px;
        top: 50%;
        left: 50%;
        display: block;
        transform: translate(-50%, -50%);
      }
    }

    .goods-name {
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
      text-align: left;
      height: 50px;
      cursor: pointer;

      &:hover {
        color: $base-color;
      }
    }

    .price-wrap {
      padding: 0 10px;
      display: flex;
      align-items: center;

      .price {
        display: flex;
        color: $base-color;
        font-size: $ns-font-size-lg;

        p {
          font-size: $ns-font-size-base;
          display: flex;
          align-items: flex-end;
        }
      }

      .market-price {
        color: #838383;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }

    .sale-num {
      padding: 0 10px;
      display: flex;
      color: #838383;
      font-size: $ns-font-size-sm;

      p {
        color: #4759a8;
      }
    }

    .shop_name {
      padding: 0 10px;
      display: flex;
      color: #838383;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      word-break: break-all;
      text-align: left;
    }

    .saling {
      padding: 0 10px;
      display: flex;
      font-size: $ns-font-size-sm;
      line-height: 1;

      .free-shipping {
        background: $base-color;
        color: #ffffff;
        padding: 3px 4px;
        border-radius: 2px;
        margin-right: 5px;
      }

      .is_own {
        color: #ffffff;
        background: #ff850f;
        border: 1px solid;
        margin-right: 5px;
        display: flex;
        align-items: center;
        padding: 3px 4px;
        border-radius: 2px;
      }

      .promotion-type {
        color: $base-color;
        border: 1px solid $base-color;
        display: flex;
        align-items: center;
        padding: 1px 3px;
      }
    }

    .item-bottom {
      display: flex;
      margin-top: 5px;

      .collection {
        flex: 1;
        border: 1px solid #e9e9e9;
        border-right: none;
        border-left: none;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .cart {
        flex: 2;
        border: 1px solid #e9e9e9;
        border-right: none;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}

.empty {
  margin-top: 45px;
}

.pager {
  text-align: center;
  margin: 30px 0;
}



/*筛选*/
.sort-box {
  position: relative;
  z-index: 4;
  _zoom: 1;
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  background: #F1F1F1;
  margin-bottom: 5px;
  display: flex;
}

.sort {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  zoom: 1;

  .f-sort {
    float: left;
    margin-right: 13px;

    .item {
      display: flex;
      align-items: center;
      cursor: pointer;
      float: left;
      padding: 0 9px;
      height: 23px;
      border: 1px solid #CCC;
      line-height: 23px;
      margin-right: -1px;
      background: #FFF;
      color: #333;

      &:hover {
        background: $base-color;
        color: #fff;
      }
    }

    a.curr {
      border-color: $base-color;
      background: $base-color;
      color: #FFF;
    }

    .item-other {
      align-items: center;
      padding: 0px 15px;
      border: 1px solid #f1f1f1;
      background: #f1f1f1;
      border-left: none;
      cursor: pointer;

      &:hover {
        background: none;
        color: #fff;
      }
    }
  }

  .input-wrap {
    position: relative;
    float: left;
    width: 200px;
    height: 28px;
    margin-right: 12px;
    display: flex;

    span {
      width: 12px;
      line-height: 23px;
      color: #333;
      text-align: center;
    }

    .el-input {
      border-color: #ccc;
      color: #333;
    }

    .el-button {
      margin: 0 5px;
    }
  }
}

/*地区*/
.sort-city {
  position: absolute;
  top: 3px;
  right: 5px;
  height: 24px;
  min-width: 80px;
  margin: 7px 0 7px 9px;
  display: flex
}

.region-label {
  width: 40px;
}

.region-selected {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin: 0;
  width: auto;
  padding: 0 10px;
  height: 23px;
  line-height: 23px;
  border: 1px solid #e5e5e5;
  z-index: 2;
  background-color: #fff;
}

.region-list {
  width: 450px;
  position: absolute;
  z-index: 10;
  top: 38px;
  right: -5px;
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 10px 15px;
  line-height: 18px;
  height: auto;

  .nav-tabs {
    border-bottom: 2px solid $base-color;
    height: 30px;

    >li {
      margin-bottom: -2px;
      float: left;

      >div {
        padding: 4px 8px;
        border: 2px solid transparent;
        height: 20px;
        display: inline-block;
        line-height: 20px;
        transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1);
        cursor: pointer;

        &:hover {
          border-color: transparent;
        }
      }

      &.active {
        >div {
          color: $base-color;
          border: 2px solid;
          border-bottom-color: #fff !important;
        }
      }
    }
  }

  .tab-content {
    >.active {
      padding: 10px 0;
    }

    >.tab-pane {
      display: none;

      &.active {
        display: block;
      }

      li {
        float: left;
        width: 25%;
        height: 36px;
        line-height: 16px;

        &.selected {
          color: $base-color;
        }

        span {
          cursor: pointer;

          &:hover {
            color: $base-color;
          }
        }
      }
    }
  }
}

.region-list::before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  transform: rotate(225deg);
  top: -10px;
  right: 20px;
  background-color: #fff;
}

.region-selected:hover+.region-list {
  display: block;
}


/*分类*/
.c-filter {
  display: inline-block;
  margin: 0px auto;
  width: 100%;
  padding: 0px;
}

.category_label {
  float: left;
  line-height: 40px
}

.category_line {
  position: relative;
  float: left;
  height: 40px;
  margin-left: 18px;
}

.category_tab .purchase_cArrow {
  float: right;
  width: 15px;
  height: 6px;
  /*background-image: url(../images/Arrow_old.png);*/
  top: 10px;
  margin-left: 0;
  line-height: 30px;
}

.category_line li {
  float: left;
  position: relative;
  z-index: 1;
}

.category_tab {
  width: 100px;
  height: 28px;
  line-height: 26px;
  border: 1px solid #bbbbbb;
  margin-top: 6px;
  padding: 0 6px;
  background-color: #fff;
  cursor: pointer;
}

.s-arrowT {
  position: relative;
  display: block;
  width: 16px;
  height: 12px;
  /*background-image: url(../images/s-arrowT.png);*/
  background-repeat: no-repeat;
  background-size: contain;
  top: 6px;
  margin: 0 8px;
}

.category_show {
  display: inline-block;
  width: 72px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category_box {
  position: absolute;
  width: 300px;
  height: 162px;
  border: 1px solid #bbbbbb;
  background-color: #fff;
  z-index: 10;
  top: 34px;
  padding: 5px 10px;
  overflow: auto;
}

#category_box_b {
  left: 146px;
}

#category_box_c {
  left: 292px;
}

.category_tab.actived {
  z-index: 9;
  border: 1px solid #666;
  border-bottom: 0;
}

.category_tab.actived .purchase_cArrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.category_box span {
  display: inline-block;
  float: left;
  height: 20px;
  line-height: 20px;
  margin: 5px;
  cursor: pointer;
}

.category_box span.active {
  color: red;
}
</style>
